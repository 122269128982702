.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  color: var(--textColor);
}
.popup_inner {
  animation: popup 0.2s;
  background: white;
  padding: 1rem;
  width: 80%;
  border-radius: 4px;
  position: relative;
}
.password-input {
  position: absolute;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  border-radius: 4px;
  padding: 1em;

  .networkList__name {
    margin-bottom: 8px;
  }
}

.popup_heading {
  font-size: 1.2rem !important;
  text-align: center;
}

.networkList {
  margin-top: 18px;
  display: flex;
  flex-direction: column;

  &__close {
    position: absolute;
    right: -15px;
    top: -15px;
    border-radius: 100%;
    overflow: hidden;
    z-index: 9999;
  }

  &__name {
    font-size: 1rem;
    font-weight: bold;
  }
  &__item {
    display: flex;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
    height: 50px;
    &:hover {
      background-color: whitesmoke;
    }
  }
}

@keyframes popup {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
