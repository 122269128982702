/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.button.is-rounded {
  border-radius: 3px !important;
}
.chat-header {
  height: var(--headerHeight);
  padding: 12px 24px;
  background: var(--primary);

  .title,
  .subtitle {
    color: #fff !important;
  }

  &.is-info {
    .title,
    .subtitle {
      color: #fff !important;
    }
  }
}
.chat-body {
  background: var(--chatBody);
  position: relative;
  overflow: auto;
  padding: 0 !important;
}
.chat-footer {
  height: var(--footerHeight);
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;

  footer {
    width: 100%;

    form {
      padding: 0 0.75em;
    }
  }

  .input {
    border-radius: 0;
  }
  .button {
    border-radius: 0;
  }
}

.chats {
  width: 100%;
  padding: 3rem 1.5rem;

  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - var(--headerHeight) - var(--footerHeight));

  p {
    color: red;

    .tag:not(body) {
      min-height: 2em;
      white-space: normal;
      height: auto;
    }
  }
}

/*
  Dashboard CSS
*/

.fa-spinner {
  width: 100%;
  text-align: center;
}
.step-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
  padding: 0.2em 0.5em;
  border-radius: 4px;

  &.is-complete {
    background: var(--success);
    .step-block__title,
    .step-block__step {
      color: #fff;
    }
  }

  &__step {
    padding: 0 0.75em;
  }
  &__title {
    flex-grow: 1;
    a {
      display: block;
    }
  }
}

.order-button {
  width: 100%;
  margin-bottom: 10px;

  &.incomplete {
    background-color: #0000001a !important;
  }
}

.incomplete-order {
  padding-top: 10px;
}

/*
  Checkout CSS
*/

.likes-number {
  margin-left: 10px;
}

.cvv-required {
  color: #ff3860;
}

.try-order-button {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.cvv-field {
  margin-bottom: 30px;
}

/*
  Confirmation Page CSS
*/

.confirmation-page {
  background-color: rgb(73, 73, 73) !important;

  .blocked-confirmation {
    font-size: 100px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    color: #ff3860;
  }
}

/*
  Success Page CSS
*/

.success-page {
  background-color: white !important;
  color: rgb(73, 73, 73) !important;
  text-align: center;

  .success-order {
    font-size: 100px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    color: #23d160;
  }
}

.box {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
}

.is-danger {
  background-color: #c22a1e !important;
}

.home-logo {
  background: #fff;
  padding: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 48px;
  }

  .button.is-info {
    background: var(--primary) !important;
  }
}
