:root {
  --background: #f0f3f7;
  --primary: linear-gradient(-145deg, #e6332a, #71a5da);
  --secondary: #022133;
  --textColor: #022133;
  --textColorLight: #6e7075;
  --chatPrimary: #2193d1;
  --chatSecondary: #ffffff;
  --success: #23d160;
  --headerHeight: 70px;
  --footerHeight: 54px;
  --chatBody: rgb(30, 93, 116);
}

body {
  background-color: var(--background);
  color: var(--textColor) !important;
}
.hero:not(.is-info) .title {
  color: var(--textColor) !important;
}
.hero.is-danger .title {
  color: #fff !important;
}
.hero:not(.is-info) .subtitle,
.hero:not(.is-info) .subtitle strong {
  color: var(--textColorLight) !important;
}
.hero.is-info {

  background: var(--primary) !important;
}
.button {
  border-radius: 0 !important;
}


.hero-head + .hero-body {
  height: calc(100vh - var(--footerHeight) - var(--headerHeight));
  overflow-y: auto;
}
.hero-foot {
  height: var(--footerHeight);
}

.is-intro-button {
  animation: intro 0.2s 4s forwards;
  opacity: 0.5;
  filter: grayscale(1);
}

@keyframes intro {
  0% {
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
  }
  100% {
    pointer-events: auto;
    opacity: 1;
    filter: grayscale(0);
  }
}