.tag:not(body).chat-primary {
  background-color: var(--chatSecondary);
  text-align: left;
  padding: 0.5rem;
  display: inline-block;
}
.tag:not(body).chat-secondary {
  background-color: var(--chatPrimary);
  color: #fff;
  text-align: left;
  padding: 0.5rem;
  display: inline-block;
}
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  width: 80%;
}
.slideInLeft {
  animation-name: slideInLeft;
}

.slideInRight {
  animation-name: slideInRight;
  margin-left: auto;
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translateZ(0);
  }
}
